<template>
  <ics-page-inner title="附件" :show-header="true">
    <div class="partition-area">
      <search-inner v-if="showSearch" @submit-search="search" @clear-search="reset">
        <el-col :span="6">
          <el-form-item label="附件名称：">
            <el-input v-model="searchForm.name" />
          </el-form-item>
        </el-col>
      </search-inner>
    </div>
    <div class="partition-table">
      <table-inner :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" class="tableClass" @changePageNum="changePageNum">
        <template v-if="viewMode" slot="btn-inner">
          <el-button type="primary" icon="iconfont icon-xinzeng" @click="dialog.update = true">
            添加附件
          </el-button>
        </template>
        <template slot="table-columns">
          <el-table-column prop="name" label="附件名称" :formatter="utils.isEffective" show-overflow-tooltip />
          <el-table-column prop="createdAt" label="上传日期" :formatter="utils.isEffective" show-overflow-tooltip />
          <el-table-column prop="createdBy" label="上传人" :formatter="utils.isEffective" show-overflow-tooltip />
          <el-table-column label="操作" :width="100" fixed="right">
            <template slot-scope="scope">
              <el-link type="primary" class="text-btn" @click="utils.downloadP('firm', scope.row.url)">
                下载
              </el-link>
              <el-link v-if="viewMode" type="danger" class="text-btn" @click="delFile(scope.row)">
                删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </table-inner>
    </div>
    <ics-button-inner v-if="supplyView()" :loading="loading.submit" submit-title="提交" cancel-title="返回" @submit="submitForms" @cancel="retreat" />
    <ics-dialog-inner :loading="loading.submit" :visible.sync="dialog.update" title="新增附件" submit-title="上传" cancel-title="关闭" class="form-dialog" width="40%" @submit="confirmUpdate" @close="close">
      <div class="form-inner">
        <el-form ref="uploadForm" :model="formInfo" :rules="rules" label-width="140px" label-position="left" :label-suffix="constants.labelSuffix">
          <div class="upload-inner" style="width: 90%; top: 50%; left: 50%; margin-left: 30px; margin-top: 10px;">
            <fero-upload
              ref="upload"
              v-model="formInfo.fileList"
              :limit="limit"
              :action="uploadUrl"
              :headers="headers"
              drag
              :auto-upload="false"
              :before-upload="beforeUploadFile"
              :on-success="onSuccessFile"
              :on-preview="previewFile"
              :on-error="onErrorFile"
              :on-change="onChange"
              :on-remove="onChange"
              :on-exceed="onExceed"
            >
              <i class="el-icon-upload" />
              <div class="el-upload__text">
                将文件拖到此处，或<em>点击上传</em>
              </div>
            </fero-upload>
          </div>
        </el-form>
      </div>
    </ics-dialog-inner>
  </ics-page-inner>
</template>

<script>
import { routeEnterMixin } from '@/assets/js/mixins'
import IcsDialogInner from '@/components/dialog-inner'
class SearchProp {
  name = ''
}
class FormProp {
  fileList = []
}
export default {
  name: 'IcsAttachmentInner',
  components: { IcsDialogInner },
  mixins: [routeEnterMixin],
  props: {
    // 查看状态 默认 false
    viewMode: {
      type: Boolean,
      default: false
    },
    // 是否显示查询条件 默认true
    showSearch: {
      type: Boolean,
      default: true
    },
    // 所属企业
    firmId: {
      type: [String, Number],
      default: ''
    }
  },
  data () {
    return {
      limit: 1,
      baseUploadUrl: process.env.VUE_APP_REQUEST_BASE_URL + '/tenant/b/p/firm/file/uploadFile',
      uploadUrl: '',
      headers: {
        Token: this.$store.state.token
      },
      searchForm: new SearchProp(),
      tableList: {
        list: []
      },
      formInfo: new FormProp(),
      rules: {
        fileList: [
          { required: true, message: '请上传文件' }
        ]
      },
      dialog: {
        update: false
      },
      loading: {
        list: false,
        submit: false
      }
    }
  },
  created () {
    this.getPageList()
  },
  methods: {
    supplyView () {
      if (this.$route.params.editMode === 'supplement') {
        return true
      } else {
        return false
      }
    },
    download (row) {
      this.utils.downloadFile(row.url, row.name)
    },
    delFile (row) {
      this.$confirm('确认删除此附件？', '确认删除').then(() => {
        this.api.admittance.files.deleteFile(row.id).then(result => {
          this.$message.success(result.data.message || '删除成功')
          this.search()
        })
      })
    },
    search () {
      this.searchForm.pageNum = 1
      this.getPageList()
    },
    reset () {
      this.searchForm = new SearchProp()
      this.getPageList()
    },
    getPageList () {
      this.loading.list = true
      const searchForm = this._.cloneDeep(this.searchForm)
      searchForm.firmId = this.firmId
      this.api.admittance.files.listFile(this.utils.filterTrimValue(searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    changePageNum ({ pageNum }) {
      this.searchForm.pageNum = pageNum
      this.getPageList()
    },
    del (row) {
    },
    onChange (file, fileList) {
      this.formInfo.fileList = fileList
      this.$refs.uploadForm.validateField('fileList')
    },
    confirmUpdate () {
      if (this._.isEmpty(this.formInfo.fileList)) {
        this.$message.error('请上传附件')
        return false
      }
      this.$refs.uploadForm.validate().then(() => {
        this.uploadUrl = this.baseUploadUrl + '?firmId=' + this.firmId
        this.$nextTick(() => {
          this.loading.submit = true
          this.$refs.upload.submit()
        })
      })
    },
    onExceed () {
      this.$message.error(`一次只能上传${this.limit}个文件`)
    },
    beforeUploadFile (file) {
      return true
    },
    onSuccessFile (res, file) {
      if (res.success) {
        this.$message.success(res.message || '上传成功')
        this.search()
        this.dialog.update = false
      } else {
        this.$message.error(res.message || '上传失败')
        this.formInfo.fileList = []
      }
      this.loading.submit = false
    },
    close () {
      this.formInfo = new FormProp()
      this.$refs.uploadForm.resetFields()
      this.$refs.upload.clearFiles()
    },
    previewFile (file) {
      this.utils.downloadFile(file.url, file.name)
    },
    onErrorFile (err, file, fileList) {
      this.$message.error(JSON.parse(err.message).message || '上传失败')
      this.formInfo.fileList = []
      this.loading.submit = false
      if (err.status === 401) {
        this.$store.commit('removeEmpInfo')
        window.location.href = process.env.VUE_APP_CONSOLE_DOMAIN + '/login?redirecturl=' + encodeURIComponent(window.location.href)
      }
    },
    submitForms () {
      this.$emit('submit')
    },
    retreat () {
      this.$emit('retreat')
    }
  }
}
</script>

<style lang="less">
.tableClass {
  .el-table__fixed-right {
    height: 100% !important;
  }
}
.file-list {
  .table-inner.file-list-table {
    .table-hd {
      overflow: hidden;
      .title {
        display: none;
      }
      .btn-inner {
        float: left;
      }
    }
  }
}
</style>
